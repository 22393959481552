// jQuery
import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;

// @fancyapps
require('@fancyapps/fancybox/dist/jquery.fancybox');

// jquery-nice-select
require('jquery-nice-select/js/jquery.nice-select');

// wnumb
require('wnumb/wNumb');

// scrollreveal
require('scrollreveal/dist/scrollreveal');

// air-datepicker
require('air-datepicker/dist/js/datepicker');

// jquery.maskedinput
require('jquery-mask-plugin/src/jquery.mask');

// Scripts
require('./scripts/scripts.js');
